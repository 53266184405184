import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { tweetText } from "../../utils"

import "./description.css"

const Description = ({ id, title, description }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `
  )

  const url = site.siteMetadata.siteUrl
  const locationURL = `${url}/${id}`
  const tweet = tweetText(title, locationURL)
  return (
    <div className="description-main">
      <div className="details">
        <div className="details-content">
          <div className="social-share">
            <ul>
              <li className="social facebook">
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={`https://www.facebook.com/sharer/sharer.php?u=${locationURL}`}
                >
                  <span className="icon-facebook"></span>
                </a>
              </li>
              <li className="social twitter">
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={`https://twitter.com/intent/tweet?url=${locationURL}&text=${tweet}`}
                >
                  <span className="icon-twitter"></span>
                </a>
              </li>
              <li className="social pinterest">
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={`https://www.pinterest.com/pin/create/button/?url=${locationURL}&media=${url}/static/thumbnail/${id}.jpg&description=Spread the happiness! You can make your designer or developer friends happy by sharing a multicolor CSS gradient, try it!`}
                >
                  <span className="icon-pinterest"></span>
                </a>
              </li>
            </ul>
            <p>
              Spread the happiness! You can make your designer or developer
              friends happy by sharing a multicolor CSS gradient, try it!
            </p>
          </div>
          <div className="details-description">{description}</div>
        </div>
      </div>
    </div>
  )
}

export default Description
