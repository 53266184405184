import React from "react"

import Description from "../components/item/description"

import {
  Layout,
  Container,
  SEO,
  Gradients,
  Subscription,
} from "../components/common"

import { relatedItems } from "../utils"

export default ({
  pageContext: { item, items, description, stats, footerGradient },
}) => {
  const relatedItemsArray = relatedItems(item, items)
  const totalItems = items.length
  return (
    <Layout
      item={item}
      totalItems={totalItems}
      footerGradient={footerGradient}
      stats={stats}
    >
      <Container>
        <SEO
          title={`${item.title} Multicolor Gradient`}
          description={`Quickly design your next project with a multicolor gradient named ${item.title}. Use CSS Gradient codes or background image. No attribute required!`}
          ogImage={`${item.id}.jpg`}
        />
        <Description
          id={item.id}
          title={item.title}
          description={description}
        />
        <Gradients related items={relatedItemsArray} />
        <Subscription />
      </Container>
    </Layout>
  )
}
